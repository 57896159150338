/**
 * Stores build information about <b>this specific version of the `ruffle-core` library</b>.
 *
 * It does not represent the version of Ruffle that may be in use by the page (for example, if a browser extension overrides it).
 *
 * @privateRemarks
 * This is generated at build time via `set_version.ts`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2025-01-17",
    versionChannel: "nightly",
    buildDate: "2025-01-17T00:06:26.078Z",
    commitHash: "60d0620c1120a0973c619a3b4fa5780a0777bd69",
};
